'use strict';

//function error(title, message){
//    $(".alert-warning strong").html(title);
//    $(".alert-warning span").html(message);
//    $(".alert-warning").fadeIn(300).delay(6000).fadeOut(300)
//}
var current_image = 0;
function galleryNext(right){
    var total = $("#portfolio_images > li").length;
    if(right == true){
        current_image++;
        if(current_image >= total){
            current_image = 0;
        }
    }else{
        current_image--;
        if(current_image < 0){
            current_image = total - 1;
        }
    }
    $("#portfolio_images > li").removeClass("current");
    $("#portfolio_images > li").eq(current_image).addClass("current");

}

var MemoryGame = React.createClass({
    hideLogo: function(index){
        var _this = this;
        setTimeout(function(){

            var logotypes_array = _this.state.logotypes_array;
            logotypes_array[index].scrollInited = true;
            _this.setState({logotypes_array: logotypes_array});

        }, 2000 + index * 70);

    },
    firstScrollInit: function(){
            var logotypes_array = this.state.logotypes_array;
            for (var i = 0; i < logotypes_array.length; i++) {
                this.hideLogo(i);
            }
            this.state.firstScrollInit = true;
    },
    getInitialState: function() {
        return {
            current: null,
            click_current: null,
            success_array: [],
            firstScrollInit: false,
            solved: false,
            logotypes_array: lang.logotypes_array
        };
    },
    componentWillMount: function() {

        function shuffle(o){
            for(var j, x, i = o.length; i; j = Math.floor(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
            return o;
        }

        var logotypes_array = this.state.logotypes_array;
        for(var i = 0; i< logotypes_array.length; i++){
            logotypes_array[i].num =  i;
            logotypes_array[i].scrollInited = false;
        }

        logotypes_array = shuffle(logotypes_array);
        logotypes_array = logotypes_array.concat(shuffle(logotypes_array.concat()))
        this.setState({"logotypes_array": logotypes_array});
    },
    clickTimeout: 0,
    onClick: function(e) {

        var cell_index = $($(e.target).parent().parent().parent()).index();

        var t = new Date().getTime();
        if(t - this.clickTimeout < 300 || this.state.current == cell_index){
            return;
        }

        this.clickTimeout = t;

        $(e.target).parent().parent().addClass("hover");
        var success_array = $(".flipperHover");

        if(this.state.logotypes_array[cell_index].isCorrect){
            console.log("isCorrect");
            return;
        }
        if(this.state.current == null) {
            this.state.current = cell_index;
            this.setState({click_current: null});
        }else{

            $("#memory_game_canvas").css({"pointer-events":"none"});
            if(success_array[cell_index] && success_array[this.state.current] && this.state.logotypes_array[cell_index].num == this.state.logotypes_array[this.state.current].num){
                $("#memory_game_canvas").css({"pointer-events":"auto"});
                $(".flipperHover").removeClass("hover");
                this.state.logotypes_array[this.state.current].first = true;
                this.state.logotypes_array[cell_index].isCorrect = true;
                this.state.logotypes_array[this.state.current].isCorrect = true;
                var _this = this;
                setTimeout(function(){
                    _this.setState({click_current: _this.state.logotypes_array[cell_index]});
                    _this.state.click_current = null;
                }, 1000);

            }else{
                setTimeout(function(){
                    $("#memory_game_canvas").css({"pointer-events":"auto"});
                }, 700);
                setTimeout(function(){
                    $(".flipperHover").removeClass("hover");
                }, 750);
            }
            this.state.current = null;


        }
        var correct_count = 0;
        for (var i = 0; i < this.state.logotypes_array.length; i++) {
            if(this.state.logotypes_array[i].isCorrect){
                correct_count++;
            }
        }

        if(correct_count == this.state.logotypes_array.length){
            this.skipGame();
        }

        this.setState({success_array: success_array, click_current: null});


    },
    markBrandAsCorrect: function(logotype, delay, hide){
        var _this = this;
        setTimeout(function() {
            logotype.isCorrect = true;
            if(hide == true){
                logotype.finish = true;
            }
            _this.setState({logotypes_array: _this.state.logotypes_array});
        }, delay);
    },
    skipGame: function() {
        this.state.solved = true;
        var skip_Array = [];

        for (var i = 0; i < this.state.logotypes_array.length; i++) {
            if(!this.state.logotypes_array[i].isCorrect){
                skip_Array.push(this.state.logotypes_array[i]);
            }
        }
        var skip_Array_pares = [];
        for (var i = 0; i < skip_Array.length; i++) {
            for (var ii = i; ii < skip_Array.length; ii++) {
                if(skip_Array[i].num==skip_Array[ii].num){
                    skip_Array[i].first = true;
                    skip_Array_pares[skip_Array[i].num] = [skip_Array[i], skip_Array[ii]];
                    skip_Array.splice(ii, 1);
                }
            }
        }

        for (var i = 0; i < skip_Array_pares.length; i++) {
            if(skip_Array_pares[i] && skip_Array_pares[i][0]) this.markBrandAsCorrect(skip_Array_pares[i][0], i * 100, true);
            if(skip_Array_pares[i] && skip_Array_pares[i][1]) this.markBrandAsCorrect(skip_Array_pares[i][1], i * 100);
        }

        var _this = this;

        setTimeout(function() {
            _this.setState({half_hidden: true});
        }, 2000);

    },
    showItem: function(e) {
        this.state.current_image = 0;
        var cell_index = $($(e.target).parent().parent().parent().parent()).index();
        this.setState({click_current: this.state.logotypes_array[cell_index]});
        this.state.click_current = null;

    },
    render: function() {
        if(!this.state.firstScrollInit && this.props.inited){
            this.firstScrollInit();
        }
        var memory_logotypes_greed = [];

        for (var i = 0; i < this.state.logotypes_array.length; i++) {
                var img = "img/our_work/" + this.state.logotypes_array[i].logo;

                var className = "flipperHover ";
            var correctClick = this.state.logotypes_array[i].isCorrect ? this.showItem : null;
                className += this.state.logotypes_array[i].isCorrect && !(this.state.half_hidden && i > this.state.logotypes_array.length/2 - 1) ? "success hover " : "";
                className += this.state.logotypes_array[i].finish ? "finish " : "";
                className += (this.state.half_hidden && i > this.state.logotypes_array.length/2 - 1) ? "hideCard " : "";
                className += this.state.logotypes_array[i].scrollInited ? "first_init " : " ";
                memory_logotypes_greed.push(<li key={i} className="flip-container">
                    <div className={className} ontouchstart="this.classList.toggle('hover');">
                        <div className="flipper">
                            <div className="front" ontouchstart={this.onClick} onClick={this.onClick}></div>
                            <div className="back" ontouchstart={correctClick} onClick={correctClick}><img src={img}/></div>
                        </div>
                    </div>
                </li>);

        }
        var popup_div = "";
        if(this.state.click_current){
            $("#container").addClass("blur");
            var popup_div_html = "<ul id='portfolio_images'>";
            if(this.state.click_current.images){
                var _this = this;
                this.state.click_current.images.forEach(function(img,index) {
                    var className = _this.state.current_image == index ? "current " : "";
                    popup_div_html += '<li class="' + className + '"><img src="' + img + '"/></li>';
                })
                popup_div_html += "</ul>";
                if(this.state.click_current.images.length > 1){
                    popup_div_html += "<img class='arrow' onClick='galleryNext(false)' src='img/a_left.svg'/>";
                    popup_div_html += "<img class='arrow' onClick='galleryNext(true)' src='img/a_right.svg'/>";
                }

                }else if(this.state.click_current.youtube){
                popup_div_html = '<iframe id="youtube" src="'+this.state.click_current.youtube+'&controls=' + (iOS ? 1 : 0) + '&amp;" allowFullScreen></iframe>';
            }
            var class_image = this.state.click_current.images ? "img" : "";
            current_image = 0;
            popup_div = '<div id="portfolio_popup" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">\
                    <div onclick="closePopup()" class="close_bg"></div>\
                    <div id="modal-dialog" class="' + class_image + '">\
                        <a href="javascript:closePopup()" class="close_x">Close “x”</a>\
                        <div id="modal-content">\
                            <div id="modal-body">'+ popup_div_html +'</div>\
                        </div>\
                        <div class="popup_desc">' + this.state.click_current.desc + '</div>\
                    </div>\
                </div>';
            $("#portfolio_popup").remove();
            $("body").append(popup_div);
            $("#portfolio_popup").addClass("show");
            if(!this.state.click_current.images){
                $(".popup_desc").addClass("video");
            }
            resize();
        }

        return (
                <ul>
                    {memory_logotypes_greed}
                    <b onClick={this.skipGame} disabled={this.state.solved} className="skip_game">{lang.skip_game}</b>
                </ul>
        )}
});

var MemoryGameRendered;